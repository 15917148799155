<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-data-table
        :headers="headers"
        :items="desserts"
        :options.sync="options"
        :server-items-length="totalDesserts"
        :loading="loading"
        dense
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Master Anggota</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              @keyup.enter="searching()"
              append-icon="mdi-magnify"
              label="Kode / nama anggota"
              single-line
              hide-details
            ></v-text-field>
            <v-btn outlined text @click="searching()"> Cari </v-btn>

            <v-spacer></v-spacer>

            <v-dialog v-model="dialog" max-width="700px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="openDialog()"> Tambah </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-alert dense border="left" type="warning" v-if="editedIndex != -1">
                      Update Jenis Upah akan merubah <b>Data Anggota dan Data Tabungan Utama Anggota</b> <br />
                      Jika akan merubah Jenis Upah Anggota pastikan <b>Semua Pinjaman Anggota</b> sudah dilunasi
                      terlebih dahulu
                    </v-alert>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field readonly v-model="editedItem.kode_anggota" label="Kode Anggota *"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.divisi" label="Bagian *"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.jabatan" label="Jabatan *"></v-text-field>
                      </v-col>
                      <!-- <v-col cols="12" sm="6" md="4">
                        <v-checkbox v-model="editedItem.wilayah_luar" @change="wilayahLuarCheck()">
                          <template v-slot:label>
                            <div>Wilayah Luar</div>
                          </template>
                        </v-checkbox>
                      </v-col> -->
                      <v-col cols="12" sm="6" md="8">
                        <v-text-field v-model="editedItem.wilayah" label="Wilayah *"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.no_referensi" label="No Referensi *"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.bank" label="Bank"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.no_rekening" label="No Rekening *"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.nama" label="Nama *"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <label for="">Jenis Kelamin *</label>
                        <v-select
                          v-model="editedItem.jenis_kelamin"
                          :items="jenis_kelamin"
                          item-text="text"
                          item-value="value"
                          dense
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <!-- <label>Tgl Lahir</label> -->
                        <!-- <input
                          type="date"
                          v-model="editedItem.tgl_lahir"
                          placeholder="dd-mm-yyyy"
                          value=""
                          min="1997-01-01"
                          max="2030-12-31"
                        /> -->
                        <v-text-field
                          type="date"
                          label="Tgl Lahir"
                          v-model="editedItem.tgl_lahir"
                          placeholder="dd-mm-yyyy"
                          dense
                          outlined
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="editedItem.nik" label="NIK *"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <!-- <label>Tgl Masuk *</label> -->
                        <!-- <input type="date" v-model="editedItem.tgl_masuk" /> -->
                        <v-text-field
                          type="date"
                          label="Tgl Masuk *"
                          v-model="editedItem.tgl_masuk"
                          dense
                          outlined
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <label for="">Jenis Upah *</label>
                        <v-select
                          v-model="editedItem.jenis_upah"
                          :items="jenis_upah"
                          item-text="text"
                          item-value="value"
                          dense
                          outlined
                          @change="jenisUpahChange"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.jumlah_upah" label="Jumlah Upah *"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" v-if="editedIndex == -1">
                        <v-text-field value="5000" readonly label="Simpanan Pokok *"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" v-if="editedIndex == -1">
                        <v-text-field value="Sesuai dengan password default" readonly label="Password *"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" @click="close"> Batal </v-btn>
                  <v-btn color="primary" @click="save"> Simpan </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" @click="closeDelete">Batal</v-btn>
                  <v-btn color="secondary" @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.tgl_lahir="{ item }">
          {{ formatDate(item.tgl_lahir) }}
        </template>
        <template v-slot:item.aksi="{ item }">
          <!-- <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
          <v-icon small class="mr-2" @click="editItem(item)"> {{ icons.mdiPencil }} </v-icon>
          <router-link :to="{ name: 'detailanggota', params: { id_anggota: item.id } }">
            <v-btn class="primary" small> Keluar/Lunasi</v-btn>
          </router-link>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" small @click="getDataFromApi"> Reload</v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiPencil } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  setup() {
    return {
      icons: {
        mdiPencil,
      },
    }
  },
  data: () => ({
    // datatable

    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'No', value: 'no', sortable: false },
      { text: 'Kode Anggota', value: 'kode_anggota', sortable: false },
      { text: 'Nama', value: 'nama', sortable: false },
      { text: 'Jns Kelamin', value: 'jenis_kelamin', sortable: false },
      { text: 'Tgl Lahir', value: 'tgl_lahir', sortable: false },
      { text: 'Jns Upah', value: 'jenis_upah', sortable: false },
      { text: 'Bagian', value: 'divisi', sortable: false },
      { text: 'wilayah', value: 'wilayah', sortable: false },
      { text: 'Aksi', value: 'aksi', sortable: false },
    ],
    totalDesserts: 0,
    desserts: [],
    loading: true,
    options: {},
    editedIndex: -1,
    editedItem: {
      kode_anggota: 'auto',
      no_referensi: '',
      jabatan: '',
      // wilayah_luar: '',
      wilayah: '',
      bank: '',
      no_rekening: '',
      nama: '',
      tgl_lahir: '',
      nik: '',
      tgl_masuk: '',
      jenis_upah: '',
      jumlah_upah: '',
      jenis_kelamin: 'PRIA',
    },
    defaultItem: {
      kode_anggota: 'auto',
      no_referensi: '',
      jabatan: '',
      // wilayah_luar: '',
      wilayah: '',
      bank: '',
      no_rekening: '',
      nama: '',
      tgl_lahir: '',
      nik: '',
      tgl_masuk: '',
      jenis_upah: '',
      jumlah_upah: '',
    },
    // jenis_upah: [
    //   { text: 'HARIAN', value: 'HARIAN' },
    //   { text: 'MINGGUAN', value: 'MINGGUAN' },
    //   { text: 'BULANAN', value: 'BULANAN' },
    // ],
    jenis_upah: ['HARIAN', 'MINGGUAN', 'BULANAN'],
    jenis_kelamin: ['PRIA', 'WANITA'],
    search: '',
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Anggota' : 'Edit Anggota'
    },
    token() {
      return this.$store.getters.token
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },

  created() {
    // this.initialize()
  },

  methods: {
    getDataFromApi() {
      this.loading = true
      const { page, itemsPerPage } = this.options

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('search', this.search)
      form.append('page', page)
      form.append('itemsPerPage', itemsPerPage)
      axios
        .post(`${apiRoot}/api/Anggota/getTable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            this.totalDesserts = response.data.total
          } else {
            alert(response.data.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
    openDialog() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        // Object.assign(this.desserts[this.editedIndex], this.editedItem)
        this.$store.dispatch('loading', true)
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id', this.editedItem.id)
        form.append('no_referensi', this.editedItem.no_referensi)
        form.append('jabatan', this.editedItem.jabatan)
        // form.append('wilayah_luar', this.editedItem.wilayah_luar)
        form.append('wilayah', this.editedItem.wilayah)
        form.append('bank', this.editedItem.bank)
        form.append('no_rekening', this.editedItem.no_rekening)
        form.append('nama', this.editedItem.nama)
        form.append('tgl_lahir', this.editedItem.tgl_lahir)
        form.append('nik', this.editedItem.nik)
        form.append('tgl_masuk', this.editedItem.tgl_masuk)
        form.append('jenis_upah', this.editedItem.jenis_upah)
        form.append('jumlah_upah', this.editedItem.jumlah_upah)
        form.append('divisi', this.editedItem.divisi)
        form.append('jenis_kelamin', this.editedItem.jenis_kelamin)
        axios
          .post(`${apiRoot}/api/Anggota/updateAnggota`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.search = ''
              this.options.page = 1
              this.getDataFromApi()
              this.close()
            } else {
              alert(response.data.message)
            }
            this.$store.dispatch('loading', false)
          })
          .catch(error => {
            alert(error)
            this.$store.dispatch('loading', false)
          })
      } else {
        this.$store.dispatch('loading', true)
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('no_referensi', this.editedItem.no_referensi)
        form.append('jabatan', this.editedItem.jabatan)
        // form.append('wilayah_luar', this.editedItem.wilayah_luar)
        form.append('wilayah', this.editedItem.wilayah)
        form.append('bank', this.editedItem.bank)
        form.append('no_rekening', this.editedItem.no_rekening)
        form.append('nama', this.editedItem.nama)
        form.append('tgl_lahir', this.editedItem.tgl_lahir)
        form.append('nik', this.editedItem.nik)
        form.append('tgl_masuk', this.editedItem.tgl_masuk)
        form.append('jenis_upah', this.editedItem.jenis_upah)
        form.append('jumlah_upah', this.editedItem.jumlah_upah)
        form.append('divisi', this.editedItem.divisi)
        form.append('jenis_kelamin', this.editedItem.jenis_kelamin)
        axios
          .post(`${apiRoot}/api/Anggota/add`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.search = ''
              this.options.page = 1
              this.getDataFromApi()
              this.close()
            } else {
              alert(response.data.message)
            }
            this.$store.dispatch('loading', false)
          })
          .catch(error => {
            alert(error)
            this.$store.dispatch('loading', false)
          })
      }
    },

    jenisUpahChange() {},
    searching() {
      this.options.page = 1
      this.getDataFromApi()
    },
  },
}
</script>
